<template>
  <div class="iss-main contactFrequency">
    <div class="gotoBack" @click="gotoBack">
      返回个人中心<RightOutlined class="ml-8" />
    </div>
    <div class="itemAll">
      <div class="colItem">
        <div class="leftOne">
          <div class="text">奖励金币</div>
          <div class="danwei">
            <span class="mun">{{ inviterData.totalGold }}</span>
            个
          </div>
        </div>
        <div class="rightOne">
          <img src="@/assets/images/play/reward.png" class="reward" />
        </div>
      </div>
      <div class="colItem">
        <div class="leftTwo">
          <div class="text">邀请好友</div>
          <div class="danwei">
            <span class="mun">{{ inviterData.totalInviterNum }}</span>
            人
          </div>
        </div>

        <div class="rightTwo">
          <img src="@/assets/images/play/invite.png" class="reward" />
        </div>
      </div>
    </div>

    <div class="mainContent">
      <a-tabs v-model:activeKey="activeKeyRight" class="left">
        <a-tab-pane key="1" tab="邀请方式">
          <div class="typeClass">
            <a-tooltip placement="right">
              <template #title>
                <div>
                  1.邀请的好友注册人脉宝盒账号您和好友双方均可获得50金币。
                </div>
                <div>
                  2.邀请的好友注册人脉宝盒账号并完成新手指引，您和好友双方均可获得100金币。
                </div>
                <div>
                  3.每成功邀请一位好友完成注册和新手指引，您与好友均可获得最多150金币。
                </div>
                <div>
                  4.
                  以上金币奖励适用每个有效用户，邀请好友越多获得的金币奖励越多。
                </div>
                <!--                <div>-->
                <!--                  5.-->
                <!--                  以上金币获取方法适用每个有效用户，邀请好友越多金币返佣越多。-->
                <!--                </div>-->
              </template>
              <div class="guize activeColor">
                <QuestionCircleOutlined class="activeColor" />
                规则说明
              </div>
            </a-tooltip>

            <div class="lingqu">
              领取<span class="jingbi">无限金币</span
              >每成功邀请一位好友，您与好友均可获得最多300金币。
            </div>
          </div>
          <div class="content">
            <div class="listClass listClassT" ref="yaoqingT" id="idsss">
              <div class="itemOne">
                <div class="yaoqing3">
                  <div class="jinbi">
                    邀请码：{{ inviterData.invitationCode }}
                  </div>
                  <div class="qrCode qrCodeT">
                    <img
                      src="@/assets/images/play/qrCode.png"
                      class="qrBiankuang"
                    />
                    <!-- 这里放置二维码 -->
                    <vue-qr
                      :text="handleInvitLine(inviterData.invitationCode)"
                      :margin="0"
                      :size="120"
                      class="vueQr vueQrT"
                    />
                    <!--          二维码过期，有刷新动作，支付成功有成功提示交互      -->
                    <!--                    <div class="shuaxin" >二维码已过期，请刷新</div>-->
                    <!--                    <div class="shuaxin" >支付成功！</div>-->
                  </div>
                  <div class="new">请扫码下载人脉宝盒，填写邀请码加入。</div>
                </div>
              </div>
            </div>

            <div v-for="item in list" :key="item.id" class="listClass">
              <div class="itemOne">
                <div v-if="item.id === '2' || item.id === '1'">
                  <div class="name">{{ item.lebal }}</div>
                  <div class="jinbi">{{ item.text }}</div>
                </div>
                <div v-if="item.id === '3'">
                  <div class="name">
                    {{ item.lebal }}：{{ inviterData.invitationCode }}
                  </div>
                </div>
                <!--            邀请码    -->
                <div class="yaoqing1" v-if="item.id === '1'">
                  <!--                  <div class="name">{{ item.lebal }}</div>-->
                  <!--                  <div class="jinbi">{{ item.text }}</div>-->
                  <div>{{ inviterData.invitationCode }}</div>
                </div>

                <div class="yaoqing2" v-if="item.id === '2'">
                  <div>我在人脉宝盒等你，用这个专属链接加入我们吧！</div>
                  <div>邀请码：{{ inviterData.invitationCode }}</div>
                  <!--                https://www.baidu.com/  -->
                  <div>
                    邀请链接：{{ handleInvitLine(inviterData.invitationCode) }}
                  </div>
                </div>

                <div class="yaoqing3" v-if="item.id === '3'">
                  <div class="jinbi">{{ item.text }}</div>
                  <div class="qrCode">
                    <img
                      src="@/assets/images/play/qrCode.png"
                      class="qrBiankuang"
                    />
                    <!-- 这里放置二维码 -->
                    <vue-qr
                      :text="handleInvitLine(inviterData.invitationCode)"
                      :margin="0"
                      :size="120"
                      class="vueQr"
                    />
                    <!--          二维码过期，有刷新动作，支付成功有成功提示交互      -->
                    <!--                    <div class="shuaxin" >二维码已过期，请刷新</div>-->
                    <!--                    <div class="shuaxin" >支付成功！</div>-->
                  </div>
                </div>

                <a-button
                  class="cursor-p Abut"
                  v-if="item.id === '1'"
                  @click="getVisitLink(inviterData.invitationCode)"
                >
                  复制邀请码
                </a-button>

                <a-button
                  class="cursor-p Abut"
                  v-if="item.id === '2'"
                  @click="getVisitLinkT(inviterData.invitationCode)"
                >
                  复制邀请链接
                </a-button>

                <a-button
                  class="cursor-p Abut mt-60"
                  v-if="item.id === '3'"
                  @click="getVisitCode()"
                >
                  下载二维码
                </a-button>
              </div>
              <!--              <div class="itemTwo">{{ item.shijian }}</div>-->
            </div>
          </div>

          <!--  邀请海报  -->
          <div class="posters">
            <div class="postersTitle fs-20 mb-24">邀请海报</div>
            <div class="postersContent content">
              <div class="listClass postersClass">
                <div class="itemOne">
                  <img src="@/assets/images/play/titile.png" class="titile" />
                  <div class="userMessage">
                    <div class="mr-4">
                      <a-avatar :src="setAvatar(userData.avatar)" :size="20" />
                      <!--         <a-avatar :src="setAvatar(value)" :size="128" />-->
                    </div>
                    <div>{{ userData.userName }}邀请您加入人脉宝盒</div>
                  </div>
                  <div class="qrCode">
                    <img src="@/assets/images/play/banner.png" class="banner" />

                    <div class="erweima">
                      <div class="yaoqingma">
                        邀请码：{{ inviterData.invitationCode }}
                      </div>
                      <img
                        src="@/assets/images/play/qrCode.png"
                        class="qrBiankuang"
                      />
                      <!-- 这里放置二维码 -->
                      <vue-qr
                        :text="handleInvitLine(inviterData.invitationCode)"
                        :margin="0"
                        :size="114"
                        class="vueQr"
                      />
                      <div>请扫码下载人脉宝盒，填写邀请码加入。</div>
                    </div>
                  </div>
                </div>

                <a-button
                  class="cursor-p Abut mt-32"
                  @click="getPosters(poster1)"
                >
                  下载海报
                </a-button>
              </div>

              <div class="listClass postersClass">
                <div class="itemOne" ref="poster2">
                  <img src="@/assets/images/play/titile.png" class="titile" />
                  <div class="userMessage">
                    <div class="mr-4">
                      <a-avatar :src="setAvatar(userData.avatar)" :size="20" />
                      <!--         <a-avatar :src="setAvatar(value)" :size="128" />-->
                    </div>
                    <div>{{ userData.userName }}邀请您加入人脉宝盒</div>
                  </div>
                  <div class="qrCode">
                    <img src="@/assets/images/play/banner.png" class="banner" />

                    <div class="erweima">
                      <div class="yaoqingma">
                        邀请码：{{ inviterData.invitationCode }}
                      </div>
                      <img
                        src="@/assets/images/play/qrCode.png"
                        class="qrBiankuang"
                      />
                      <!-- 这里放置二维码 -->
                      <vue-qr
                        :text="handleInvitLine(inviterData.invitationCode)"
                        :margin="0"
                        :size="114"
                        class="vueQr"
                      />
                      <div>请扫码下载人脉宝盒，填写邀请码加入。</div>
                    </div>
                  </div>
                </div>

                <a-button
                  class="cursor-p Abut mt-32"
                  @click="getPosters(poster2)"
                >
                  下载海报
                </a-button>
              </div>

              <div class="listClass postersClass">
                <div class="itemOne" ref="poster3">
                  <img src="@/assets/images/play/titile.png" class="titile" />
                  <div class="userMessage">
                    <div class="mr-4">
                      <a-avatar :src="setAvatar(userData.avatar)" :size="20" />
                      <!--         <a-avatar :src="setAvatar(value)" :size="128" />-->
                    </div>
                    <div>{{ userData.userName }}邀请您加入人脉宝盒</div>
                  </div>
                  <div class="qrCode">
                    <img src="@/assets/images/play/banner.png" class="banner" />

                    <div class="erweima">
                      <div class="yaoqingma">
                        邀请码：{{ inviterData.invitationCode }}
                      </div>
                      <img
                        src="@/assets/images/play/qrCode.png"
                        class="qrBiankuang"
                      />
                      <!-- 这里放置二维码 -->
                      <vue-qr
                        :text="handleInvitLine(inviterData.invitationCode)"
                        :margin="0"
                        :size="114"
                        class="vueQr"
                      />
                      <div>请扫码下载人脉宝盒，填写邀请码加入。</div>
                    </div>
                  </div>
                </div>

                <a-button
                  class="cursor-p Abut mt-32"
                  @click="getPosters(poster3)"
                >
                  下载海报
                </a-button>
              </div>

              <!--      todo 下载海报页面        -->
              <div
                class="listClass postersClass showPosters"
                ref="poster1"
                id="poster1"
              >
                <div class="itemOne">
                  <img src="@/assets/images/play/titile.png" class="titile" />
                  <div class="userMessage">
                    <div class="mr-4">
                      <a-avatar :src="setAvatar(userData.avatar)" :size="20" />
                      <!--         <a-avatar :src="setAvatar(value)" :size="128" />-->
                    </div>
                    <div>{{ userData.userName }}邀请您加入人脉宝盒</div>
                  </div>
                  <div class="qrCode">
                    <img src="@/assets/images/play/banner.png" class="banner" />

                    <div class="erweima">
                      <div class="yaoqingma">
                        邀请码：{{ inviterData.invitationCode }}
                      </div>
                      <img
                        src="@/assets/images/play/qrCode.png"
                        class="qrBiankuang"
                      />
                      <!-- 这里放置二维码 -->
                      <vue-qr
                        :text="handleInvitLine(inviterData.invitationCode)"
                        :margin="0"
                        :size="114"
                        class="vueQr"
                      />
                      <div>请扫码下载人脉宝盒，填写邀请码加入。</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="邀请记录">
          <iss-recording />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Tabs, Tooltip, message, Avatar } from 'ant-design-vue';
import { onMounted, reactive, ref, toRefs } from 'vue';
import VueQr from 'vue-qr/src';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons-vue';
import IssRecording from '@/views/personal/invite/recording';
import { copyText } from '@/utils';
import html2canvas from 'html2canvas';
import playApi from '@/api/play';
import { useStore } from 'vuex';
import { setAvatar } from '@/utils';
import { useRouter } from 'vue-router';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    // ACol: Col,
    // ARow: Row,
    QuestionCircleOutlined,
    ATooltip: Tooltip,
    VueQr,
    IssRecording,
    AAvatar: Avatar,
    RightOutlined,
  },
  setup() {
    const poster1 = ref(null);
    const poster2 = ref(null);
    const poster3 = ref(null);
    const yaoqingT = ref(null);
    // + `?inviterCode=${invitationCode}`
    const line = process.env.VUE_APP_SERVER_LOGIN;
    const store = useStore();
    const router = useRouter();
    const userId = store.state.account.user.id;
    const state = reactive({
      activeKeyRight: '1',

      list: [
        {
          lebal: '邀请码',
          text: '好友可输入邀请码加入人脉宝盒',
          id: '1',
        },
        {
          lebal: '邀请链接',
          text: '好友可访问链接加入人脉宝盒',
          id: '2',
        },
        {
          lebal: '邀请码',
          text: '好友可扫描二维码加入人脉宝盒',
          id: '3',
        },
      ],
      inviterData: {},
      userData: {},
    });

    // 会员个人中心信息
    playApi.userGoldNameDate('', {}).then(res => {
      if (res) {
        state.userData = res;
      }
    });

    const getPosters = () => {
      let shareContent = document.getElementById('poster1');
      html2canvas(shareContent, { useCORS: true }).then(canvas => {
        // 创建一个虚拟的a元素用于下载
        const link = document.createElement('a');
        // 将canvas转换为DataURL
        const imageData = canvas.toDataURL('image/png');
        // 设置a元素的属性
        link.href = imageData;
        link.download = 'poster.png';
        // console.log('link.download', imageData);

        // 触发a元素的点击事件
        link.click();
      });
    };

    // 生成下载链接，并且下载二维码
    const getVisitCode = () => {
      let shareContent = document.getElementById('idsss');
      html2canvas(shareContent, { useCORS: true }).then(canvas => {
        // 创建一个虚拟的a元素用于下载
        const link = document.createElement('a');
        // 将canvas转换为DataURL
        const imageData = canvas.toDataURL('image/png');
        // 设置a元素的属性
        link.href = imageData;
        link.download = 'visitCode.png';
        // console.log('visitCode下载二维码', imageData);

        // 触发a元素的点击事件
        link.click();
      });
    };

    // 邀请好友总数、金币总数、邀请方式接口
    const getUserPackageTime = () => {
      playApi.goldOrderGoldDetail('', userId).then(res => {
        if (res) {
          state.inviterData = res;
        }
      });
    };

    onMounted(() => {
      getUserPackageTime();
    });
    const handleInvitLine = invitationCode => {
      let line =
        process.env.VUE_APP_SERVER_LOGIN +'mRigister'+
        `?inviterCode=${invitationCode}`;
      return line;
    };

    return {
      ...toRefs(state),
      line,
      poster1,
      poster2,
      poster3,
      yaoqingT,
      userId,
      setAvatar,
      getUserPackageTime,
      getVisitCode,
      getPosters,
      handleInvitLine,
      gotoBack() {
        router.push({
          path: '/personal',
        });
      },
      getVisitLink(text) {
        copyText(text);
        message.success('复制成功！');
      },
      getVisitLinkT(invitationCode) {
        let text =
          '我在人脉宝盒等你，用这个专属链接加入我们吧！' +
          `邀请码：${invitationCode}，` +
          '邀请链接：' +
          process.env.VUE_APP_SERVER_LOGIN +'mRigister'+
          `?inviterCode=${invitationCode}`;

        // '我在人脉宝盒等你，用这个专属链接加入我们吧！' +
        // '\r\n邀请码：987365 ' +
        // '\r\n邀请链接：https://www.baidu.com/'

        copyText(text);
        message.success('复制成功！');
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-main {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  //padding: 40px 47px;
  padding: 19px 40px 47px;
}
.itemAll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #313d5f;
}
.colItem {
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(255, 123, 0, 0.16);
  border-radius: 4px;
  width: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 56px;
}
.danwei {
  color: #848ba0;
}
.mun {
  margin-top: 10px;
  font-size: 36px;
  color: #313d5f;
}
.mainContent {
  margin: 10px 0px;
}

.mainContent /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 1px solid #eeeeee;
}

.typeClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0px;

  .guize {
    width: 8%;
  }
  .lingqu {
    width: 92%;
    text-align: right;
    .jingbi {
      color: #ff7b00;
      margin: 0px 4px;
    }
  }
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2px;

  .listClass {
    width: 360px;
    height: 320px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(255, 123, 0, 0.16);
    padding: 32px;
    margin-bottom: 32px;

    .itemOne {
      .name {
        font-size: 18px;
      }
      .jinbi {
        font-size: 16px;
      }

      .yaoqing1 {
        font-size: 32px;
        color: #333333;
        height: 124px;
        margin: 18px 0px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .yaoqing2 {
        background: #f8f9fa;
        border-radius: 4px;
        padding: 6px 14px;
        margin: 10px 0px;
        height: 144px;
      }
      .yaoqing3 {
        //height: 124px;
        //margin: 18px 0px;

        .qrCode {
          width: 42%;
          position: relative;
          margin: 22px 0px;
          //margin-left: 20px;
          .qrBiankuang {
            width: 118px;
          }

          .vueQr {
            position: absolute;
            left: 13px;
            top: 12px;
            width: 94px;
          }
          .shuaxin {
            //position: absolute;
            //left: 14px;
            //top: 70px;
            //z-index: 1;
          }

          .qrBiankuang {
          }
        }
      }

      .Abut {
        border: 1px solid #ff7b00;
      }
    }

    .name {
    }

    .jinbi {
      margin-top: 14px;
    }
  }

  .listClassT {
    position: absolute;
    z-index: -1;
    padding: 60px 0px;
    height: 364px;
    width: 316px;
    font-size: 12px;
    text-align: center;
    .jinbi {
      font-size: 16px !important;
    }
    .qrCodeT {
      width: 42%;
      position: relative;
      margin: 26px 0px !important;
      left: 29%;
      .vueQrT {
        position: absolute;
        left: 19px !important;
        top: 12px;
        width: 94px;
      }
    }
  }
}

.posters {
  .postersTitle {
    //font-size: 20px;
    //margin-bottom: 24px;
  }
  .postersClass {
    height: 640px;
    padding: 38px 22px;
    text-align: center;
    margin-bottom: 2px;
    .itemOne {
      text-align: center;
    }
    .titile {
      width: 145px;
      height: 35px;
    }
    .userMessage {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 44px;
      margin-bottom: 24px;
      font-size: 16px;
    }
    .banner {
      width: 316px;
      height: 385px;
    }
    .qrCode {
      position: relative;

      .banner {
      }

      .erweima {
        position: absolute;
        left: 11%;
        top: 74px;

        .yaoqingma {
        }

        .qrBiankuang {
          margin: 32px 0px;
          width: 141px;
        }

        .vueQr {
          position: absolute;
          left: 69px;
          top: 66px;
        }
      }
    }
  }

  .showPosters {
    position: absolute;
    top: 525px;
    z-index: -1;
  }

  .Abut {
    border: 1px solid #ff7b00;
  }
}
.gotoBack {
  text-align: right;
  padding-bottom: 18px;
  color: #1d2129;
  cursor: pointer;
}
</style>
